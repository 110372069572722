import * as THREE from 'three'
import GUI from 'lil-gui'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';


/**
 * Canvas
 */
const canvas = document.querySelector('canvas.webgl')

/**
 * GUI
 */
const gui = new GUI()

/** 
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Scene
 */
const scene = new THREE.Scene()

const axes = new THREE.AxesHelper()
scene.add(axes)

/** 
 * Camera
 */
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 10)
camera.position.set(3, 3, 3)
camera.lookAt(0, 0, 0)
scene.add(camera)

// Orbit Controls
const control = new OrbitControls(camera, canvas)
control.enableDamping = true

// const control = 


/**
 * Objects
 */
// Plane
const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(5, 5, 2, 2),
    new THREE.MeshBasicMaterial({
        color: '#ffffff',
        wireframe: true
    })
)
plane.rotation.x = - Math.PI * 0.5
scene.add(plane)

console.log(plane)

// Board ( plane )
// const board = new THREE.Mesh(
//     new THREE.PlaneGeometry(1, 1, 1, 1),
//     new THREE.MeshBasicMaterial({
//         color: '#dddddd'
//     })
// )
// board.rotation.x = - Math.PI * 0.5
// board.position.y = 0.01
// scene.add(board)

// Board ( box )
// const board = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 0.1, 1, 1, 1, 1),
//     new THREE.MeshBasicMaterial({
//         color: '#dddddd',
//         wireframe: true
//     })
// )
// // board.rotation.x = - Math.PI * 0.5
// board.position.y = 0.01
// scene.add(board)

console.log(RoundedBoxGeometry)

// Board ( round box )
const board = new THREE.Mesh(
    new RoundedBoxGeometry(),
    new THREE.MeshBasicMaterial({
        color: '#dddddd',

    })
)
scene.add(board)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

renderer.render(scene, camera)


/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    renderer.render(scene, camera)

    control.update()

    window.requestAnimationFrame(tick)
}

tick()
